body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    height: 100vh;
}

.app-container {
    display: flex;
    height: 100%;
    width: 100%;
}

.burger-menu {
    position: fixed;
    top: 10px;
    left: 10px;
    font-size: 24px;
    cursor: pointer;
    z-index: 1001;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
}

.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 250px;
    background-color: #f4f4f4;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1000;
}

.sidebar button {
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
    width: 100%;
}

.sidebar button:hover {
    background-color: #0056b3;
}

.sidebar a {
    text-decoration: none;
}

.content {
    margin-left: 0;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    transition: margin-left 0.3s ease;
}

.content-shifted {
    margin-left: 200px;
}

.container {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    text-align: center;
}

.title {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.subtitle {
    font-size: 20px;
    margin-top: 20px;
    color: #555;
}

.description {
    font-size: 18px;
    margin-bottom: 20px;
}

.feature-list {
    list-style-type: disc;
    text-align: left;
    margin: 0 auto;
    padding: 0;
    max-width: 500px;
}

.feature-list li {
    margin-bottom: 10px;
    font-size: 16px;
}

.input-group {
    position: relative;
}

.input-group .input-field,
.input-group .select-field {
    width: 100%;
    padding-right: 40px;
}

.clear-button {
    position: absolute;
    right: 10px;
    top: 30%;
    transform: translateY(-50%);
    background-color: #f4f4f4;
    border: none;
    color: #007bff;
    font-size: 18px;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.clear-button:hover {
    color: #0056b3;
}

.input-field,
.select-field {
    width: calc(100% - 22px);
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.button {
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: calc(100% - 22px);
    margin-top: 10px;
    box-sizing: border-box;
    font-size: 16px;
}

.button:hover {
    background-color: #0056b3;
}

.flash-message {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1000;
    color: white;
    font-weight: bold;
}

.flash-message.positive {
    background-color: #4CAF50;
}

.flash-message.negative {
    background-color: #f44336;
}

.logo {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-bottom: 20px;
}

.info-section {
    text-align: center;
}

.info-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.info-section p {
    font-size: 18px;
    margin-bottom: 20px;
}

.info-section ul {
    list-style-type: disc;
    padding-left: 20px;
    text-align: left;
}

.info-section ul li {
    margin-bottom: 10px;
    font-size: 16px;
}

.select-field .react-select__control {
    width: 100%;
}

.error-text {
    color: red;
    margin-top: 10px;
    font-weight: bold;
}
