/* src/InfoTooltip.css */
.info-tooltip {
    position: relative;
    display: inline-block;
}

.info-icon {
    cursor: pointer;
    margin-left: 5px;
}

.info-text {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the icon */
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.info-tooltip:hover .info-text {
    visibility: visible;
    opacity: 1;
}
